$material_primary: #212121;
$material_dark: #000000;
$material_light: #212121;
$material_text: #ffffff;

* {
  color: $material_text;
}

body {
  //background-color: $material_primary;

  app-root {
    //position: absolute;

    app-background {
      .canvas-wrapper {
        margin: 0;
        //top: 0;
        //left: 0;
        width: 100%;
        height: 100%;
      }
      position: absolute;
      margin: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $material_dark;
    }

    #container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 80px auto 50px;
      grid-template-areas:
    "header"
    "content"
    "footer";

      header {
        grid-area: header;

        h1 {
          float: left;
        }
        nav {
          float: right;

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
            //background-color: #333;

            li {
              float: left;
              margin: 10px;
            }
          }
        }
        //background-color: $material_dark;
      }
      #content {
        grid-area: content;
      }
      footer {
        grid-area: footer;
      }
    }
  }

}

